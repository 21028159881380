<template>
    <section class="section__content-wrapper">
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title"> {{title}}</span>
                <span class="head__sub-title">&nbsp;/&nbsp;{{subtitle}}</span>
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <nav class="section__navigation pfull__12">
                <SegmentControl
                    :field_id="'component'"
                    :items="items"
                    v-model="currentItem"
                />
            </nav>

            <!--- Container --->
            <component :is="currentItem" :group-id="groupId" />
        </section>

        <section class="section__footer status_hidden">
            <button class="button button_view_base button_theme_primary mr__8" @click="onSave"> {{ $t('buttons.Save') }} </button>
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{ $t('buttons.Cancel') }} </button>
        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    // import GroupCommand_History from "./GroupCommand_History.vue";
    // import GroupCommand_Queue from "./GroupCommand_Queue.vue";
    // import GroupCommand_Templates from "./GroupCommand_Templates.vue";

    export default {
        name: "GroupCommand",
        props: [
            'groupId',
        ],
        components: {
            // GroupCommand_Templates,
            // GroupCommand_Queue,
            // GroupCommand_History
            'GroupCommand_Templates':   () => import('./GroupCommand_Templates.vue'),
            'GroupCommand_Queue':       () => import('./GroupCommand_Queue.vue'),
            'GroupCommand_History':     () => import('./GroupCommand_History.vue'),
        },
        data() {
            return {
                items: [
                    {name: 'Templates', component: 'GroupCommand_Templates'},
                    {name: 'Queue',     component: 'GroupCommand_Queue'},
                    {name: 'History',   component: 'GroupCommand_History'},
                ],
                currentItem: null,
            }
        },
        computed: {
            ...mapGetters([
                "unitsCount",
            ]),
            title(){
                return 'Name group'
            },
            subtitle(){
                return 'Send command for group'
            },
            action(){
                return (this.unitId>0) ? this.$t('buttons.Save') : this.$t('buttons.Create')
            },
        },
        methods: {
            ...mapActions([
                "setSectionWidget",
            ]),
            onCancel(){
                this.$emit('close-dialog')
            },
            onSave(){
                this.$emit('close-dialog')
            }
        },
        created() {
            this.currentItem = this.items[0]
        },
        updated() {
        },
        mounted() {
        }
    }
</script>

